
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz
        },
    })

    // *****************************************************************************************************************
    export default class DevCSharpQuizzes extends Vue {
        // Data --------------------------------------------------------------------------------------------------------
        private data() {
            return {
                cSharpIconPathAndName:
                    require("@/assets/images/c_sharp.png"),

                miscellaneousQuiz1:
                    require("@/assets/quizzes/c-sharp-miscellaneous-quiz-1.json"),

                miscellaneousQuiz2:
                    require("@/assets/quizzes/c-sharp-miscellaneous-quiz-2.json"),

                oopQuiz:
                    require("@/assets/quizzes/c-sharp-oop-quiz.json"),

                solidPrinciplesOfOopQuiz:
                    require("@/assets/quizzes/c-sharp-solid-principles-of-oop-quiz.json"),

                plankImagePathAndName:
                    require("@/assets/images/beam_01.png"),

                version1Quiz:
                    require("@/assets/quizzes/c-sharp-version-1-quiz.json"),

                version2Quiz:
                    require("@/assets/quizzes/c-sharp-version-2-quiz.json"),

                w3SchoolsQuiz:
                    require("@/assets/quizzes/c-sharp-w3-schools-quiz.json"),
            };
        }
    }
